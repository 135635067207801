
document.addEventListener('DOMContentLoaded', main);

function main() {
    newQuote();
    ll.init({ routing: false });
}

function newQuote() {
    let quote = ll._.randArray(allQuotes);
    quote.p = quote.p || 'Anonymous';

    window.quote = quote;
}